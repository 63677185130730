import React from "react";

const Loading = () => {
  return (
    <div className="animationload">
    <div className="osahanloading"></div>
</div>
  );
};

export default Loading;
